
export default (options) => {

    let imageContainer = document.querySelectorAll(options.selector);

    function setHeight() {
        [].forEach.call(imageContainer, (i) => {
            let container = i.querySelector('img'),
                text = i.querySelector('.text-wrapper');

            container.style.maxHeight = `${ (i.offsetHeight - parseInt(getComputedStyle(i).paddingTop, 10) - parseInt(getComputedStyle(i).paddingBottom, 10) ) - text.offsetHeight}px`;
        });
    }

    ['load', 'resize'].forEach((e) => {
        window.addEventListener(e, () => {
            setHeight();
        });
    });
}
