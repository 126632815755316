import anime from 'animejs';

export default (options) => {

    let items = document.querySelectorAll('.services-overview--item'),
        animeTarget = '.text-container',
        removable = true,
        added = false,
        timelines = [];

    function setAnimation() {
        [].forEach.call(items, (i, index) => {
            let timeline = anime.timeline({
                autoplay: false
            });

            anime({
                targets: i.querySelector(animeTarget),
                translateY: i.querySelector('.services-overview .text').offsetHeight + parseInt(window.getComputedStyle(i.querySelector('.services-overview .text')).marginTop, 10),
                duration: 0,
                complete: (i) => {
                  i.animatables[0].target.classList.add('is--set');
                }
            });

            timeline
            .add({
                targets: i.querySelector(animeTarget),
                translateY: 0,
                easing: 'easeInOutQuad',
                duration: options.duration,
            })
            .add({
                targets: i.querySelector('.text'),
                opacity: 1,
                easing: 'easeInOutQuad',
                duration: options.duration,
                offset: `-=${options.duration * 0.5}`
            });

            timelines[index] = {
                target: i,
                timeline: timeline
            };
        });
        removable = true;
    }

    function removeAnimation() {
        [].forEach.call(items, (i) => {
            i.querySelector(animeTarget).style = '';
            i.querySelector('.text').style = '';
        });
        removable = false;
    }

    function addListener() {
        [].forEach.call(items, (i) => {
            i.addEventListener('mouseenter', onMouseEnter, false);
            i.addEventListener('mouseleave', onMouseLeave, false);
        });
        added = true;
    }

    function onMouseEnter (e) {
        timelines.forEach( tl => {
            if(tl.target === e.currentTarget) {
                if (tl.timeline.reversed) tl.timeline.reverse();
                tl.timeline.play();
            }
        })
    }

    function onMouseLeave (e) {
        timelines.forEach( tl => {
            if(tl.target === e.currentTarget) {
                if (!tl.timeline.reversed) tl.timeline.reverse();
                tl.timeline.play();
            }
        })
    }

    function removeListener() {
        [].forEach.call(items, (i) => {
            i.removeEventListener('mouseenter', onMouseEnter, false);
            i.removeEventListener('mouseleave', onMouseLeave, false);
        });
        added = false;
    }


    ['load', 'resize'].forEach((e) => {
        window.addEventListener(e, () => {
            if(window.innerWidth > options.breakpoint) {
                setAnimation();
                if(!added) {
                    addListener();
                }
            } else {
                if(removable) {
                    if(added) {
                        removeListener();
                    }

                    setTimeout(() => {
                        removeAnimation();
                    }, options.duration);
                }
            }
        });
    });
}
