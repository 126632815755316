import Flickity from 'flickity';

export default (sliderClass, options, buttons, custom) => {
  const slider = document.querySelectorAll(sliderClass);

  [].forEach.call(slider, (s) => {
    let header = new Flickity(s, options),
        previousButton = s.querySelector(buttons.previousButton) ||
            s.parentNode.querySelector(buttons.previousButton),
        nextButton = s.querySelector(buttons.nextButton) ||
            s.parentNode.querySelector(buttons.nextButton);

    if (previousButton) {
      previousButton.addEventListener('click', () => {
        header.previous();
      });
    }

    if (nextButton) {
      nextButton.addEventListener('click', () => {
        header.next();
      });
    }

    header.on('change', () => {
      custom.lazy.update();
    });
  });
}
