import './app.scss';
import './js/polyfills';
import slider from './js/slider';
import animations from './js/animations';
import images from './js/images';
import menu from './js/menu';
import aos from './js/animateOnScroll';
import lazy from './js/lazyload';

const app = () => {
    const options = {
        breakpoints: {
            mobile: 480,
            tablet: 767,
            laptop: 1024,
            desktop: 1260,
            desktopLarge: 1440
        }
    };

    animations({
        duration: 225,
        breakpoint: options.breakpoints.laptop,
    });

    images({
        selector: '.service-teaser--image__product'
    });

    const lazyLoad = lazy;

    slider('.header-slider',
        {
            cellSelector: '.header--item',
            wrapAround: true,
            cellAlign: 'left',
            prevNextButtons: false,
            adaptiveHeight: true,
            dragThreshold: 50,
            autoPlay: 9300,
        },
        {
            nextButton: '.header-slider--next',
            previousButton: '.header-slider--prev'
        },
        {
          lazy: lazyLoad
        }
    );

    slider('.slider-impression--slider',
        {
            cellSelector: '.slider-impression--item',
            wrapAround: true,
            cellAlign: 'left',
            prevNextButtons: false,
            setGallerySize: false,
            pageDots: false,
            dragThreshold: 50,
            autoPlay: 3300,
        },
        {
            nextButton: '.slider-impression--next',
            previousButton: '.slider-impression--prev'
        },
        {
          lazy: lazyLoad
        }
    );

    menu({
        hamburger: '.hamburger',
        navMobile: '.navigation-mobile',
        navHeader: '.navigation-header',
        breakpoint: options.breakpoints.laptop,
        duration: 175,
        navigationHeight: {
            large: 125,
            small: 75
        }

    });

    aos();

};

document.addEventListener('DOMContentLoaded', app);
