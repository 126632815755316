import Velocity from 'velocity-animate';

export default (options) => {

    let hamburger = document.querySelector(options.hamburger),
        navMobile = document.querySelector(options.navMobile),
        navHeader = document.querySelector(options.navHeader),
        html = document.querySelector('html'),
        ready = true,
        active = false;

    hamburger.addEventListener('click', (e) => {
        if(ready)
            if(!active)
                show();
            else
                hide();
    }, false);

    ['load', 'resize'].forEach((e) => {
        window.addEventListener(e, () => {
            if(window.innerWidth > options.breakpoint && active)
                hide();
        });
    });

    ['load', 'scroll', 'resize'].forEach((e) => {
        window.addEventListener(e, () => {
            if(window.pageYOffset > options.navigationHeight.large && window.innerWidth > options.breakpoint)
                collapse();
            else
                relapse();
        });
    });

    function collapse() {
        navHeader.classList.add('collapse');
        html.classList.add('is--collapse');
    }

    function relapse() {
        navHeader.classList.remove('collapse');
        html.classList.remove('is--collapse');
    }

    function show() {
        hamburger.classList.add('active');
        html.classList.add('no--scroll');

        Velocity(
            navMobile,
            'slideDown',
            {
                duration: options.duration,
                begin: (els) => {
                    ready = false;
                    active = true;
                },
                complete: (els) => {
                    ready = true;
                    els[0].classList.add('open');
                }
            }
        );
    }

    function hide() {
        hamburger.classList.remove('active');
        html.classList.remove('no--scroll');

        Velocity(
            navMobile,
            'slideUp',
            {
                duration: options.duration,
                begin: (els) => {
                    ready = false;
                    active = false;
                    els[0].classList.remove('open');
                },
                complete: (els) => {
                    ready = true
                }
            }
        );
    }
}
